import React from "react";
import { Container } from "components/Container";
import { Link } from "gatsby"

export function DonateButton() {

return (<Container>
      <div className="relative bg-white  text-center">
        <div className="mx-auto mt-5">
          <Link to="/donate"
            className="inline-flex items-center rounded-sm border border-transparent bg-red-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
          >
            Donate to the Campaign
          </Link>
        </div>
      </div>
    </Container>)
}
