import React from "react"

import Layout from "components/Layout"
import { Container } from "components/Container";
import FaqData from "../../content/faq.yml"
import { Link } from "gatsby";
import { DonateButton } from "components/DonateButton"

function sliceIntoChunks(arr, chunkSize) {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        res.push(chunk);
    }
    return res;
}

const faqTemplate = props => {
  const { pageContext } = props
  const { question, answer } = pageContext

  const chunkLength = FaqData.length / 3;
  const faq = sliceIntoChunks(FaqData, chunkLength)

  return (
    <Layout>
    <Container>
    <div className="">
      <h1 className="font-display text-xl leading-7 text-slate-900">
        {question}
      </h1>
      <p className="mt-4 text-base text-slate-700">{answer}</p>
    </div>
    <DonateButton />
    <div className="mt-10 bg-gray-50 overflow-hidden rounded-lg">
        <div className="max-w-7xl mx-auto py-5 px-4 sm:px-6 lg:px-8">
      <h3 className="font-display text-xl font-medium text-slate-900">More FAQ</h3>
      <ul
        role="list"
        className="mx-auto mt-10 grid grid-cols-1 gap-x-8 lg:max-w-none lg:grid-cols-3"
      >
        {
          faq.map((col, index1) => {
            return <div>
            { col.map((row, index2) => (
              <div key={`question-${index1}-${index2}`} className="mb-5">
                <dt className="font-display text-lg leading-7 text-slate-900">
                  <Link to={row.path}>{row.question}</Link>
                </dt>
                <dd className="mt-4 text-sm text-slate-700">{row.answer}</dd>
              </div>
            ))
            }
            </div>
          })
          }
      </ul>
    </div>
    </div>

    </Container>
    </Layout>
  )
}
export default faqTemplate
export { Head } from "components/Head"
